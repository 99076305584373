@tailwind base;
@tailwind components;
@tailwind utilities;

.grained-texture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: url('./images/gr.jpg'); /* Replace with the path to your texture image */
  opacity: 0.1;
  pointer-events: none; /* Allows click events to pass through the texture layer */
  animation: grained-animation 12s linear infinite; /* Adjust the animation duration as needed */
}


@keyframes grained-animation {
  0% { background-position: 0 0; }
  100% { background-position: 5% 10%; } /* Adjust the animation speed by modifying the background-position values */
}

.animate-spinY {
    animation: spinY 4s infinite linear;
    transform-style: preserve-3d;
  }
  
  @keyframes spinY {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }


  @keyframes rotate90 {
    0% {
      transform: translate(-50%, -50%) rotateY(0);
    }
    50% {
      transform: translate(-50%, -50%) rotateY(90deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateY(0);
    }
  }
  
  .rotate-90-anim {
    animation: rotate90 3s infinite linear;
  }
  







  
@font-face {
    font-family: 'Aeonik' ;
    src: url(./fonts/Aeonik/Aeonik-Regular.woff2);
}

@font-face {
  font-family: 'Sharp' ;
  src: url(./fonts/SharpGrotesk-Bold.164b198a.woff2);
}

@font-face {
  font-family: 'Strange' ;
  src: url(./fonts/Bandseins/BandeinsStrangeMedium.woff2);
}

@font-face {
    font-family: 'Strangebold' ;
    src: url(./fonts/Bandseins/BandeinsStrangeSemiBoldExtendedHalf.woff2);
}

.grain-overlay {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05) 1%, transparent 0%);
  background-size: 3px 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
li:hover > .hidden {
  display: block;
}


@font-face {
  font-family: 'Fat' ;
  src: url(./fonts/FREEFATFONT-Regular.otf);
}


@font-face {
    font-family: 'Sans' ;
    src: url(./fonts/Bandseins/BandeinsSansRegular.woff2);
}

@font-face {
    font-family: 'Bulevar' ;
    src: url(./fonts/Bulevar/bulevar-regular-webfont-transfonter.woff2);
}


::-webkit-scrollbar {
    width: 12px;
} 

/* Track */
::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
} 

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #000000;
} 

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #CDDBFE;
}