* {
    box-sizing: border-box;
  }
  
  /* START DEMO ONLY STYLES */
  body {

    -webkit-font-smoothing: antialiased;
    background: rgb(255, 255, 255);



    margin: 0;
  }
  
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  /* END DEMO ONLY STYLES */
  

  
  .inner p {
    display: inline;
    line-height: 1.3;
  }
  
  .text-container {
    position: relative;
    overflow: hidden;
    display: block;
  }
  
  .highlighted-text:before {
    content: attr(data-text);
    display: inline-block;
    opacity: 0.8;
  }
  
  .highlighted-text {
    color: #FFF;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
    transition: clip-path 0.1s ease;
    text-shadow: 0 0 0 #111;
  }
  .scroll-text {
    margin: 20px; 
    text-align: right;

    font-size: 36px; /* Change the font size value as per your requirement */
  }
  